import React from 'react'

import "../components/Auth/Auth.css"
import RegisterForm from '../components/Auth/RegisterForm'

const Register = () => {
  return (
    <div>
      <RegisterForm />
    </div>
  )
}

export default Register