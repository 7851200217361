import React from 'react'

import "../components/Auth/Auth.css"
import LoginForm from '../components/Auth/LoginForm'

const Login = () => {
  return (
    <div>
      <LoginForm />
    </div>
  )
}

export default Login